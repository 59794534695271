<span
  *ngIf="!editing"
  class="z-fx-sc z-fx-gap-8 inline-wrapper"
  [matTooltip]="tooltip"
  [matTooltipDisabled]="!tooltip || readOnly"
  [class.zef-cursor-pointer]="useClick && !readOnly"
  [class.zef-text-muted]="!value"
  [class.zef-interactive]="interactive && !readOnly"
  (click)="editing = useClick && !readOnly"
>
  <ng-container *ngTemplateOutlet="prefix"></ng-container>

  <span class="zef-text-ellipsis z-fx-flex">
    {{ value || placeholder }}
  </span>

  <button
    *ngIf="!useClick"
    class="inline-edit z-fx-none"
    mat-icon-button
    [disabled]="readOnly"
    (click)="editing = true"
  >
    <mat-icon>edit</mat-icon>
  </button>
</span>

<mat-form-field *ngIf="editing && !readOnly" class="zef-input-small zef-input-space">
  <div *ngIf="prefix" matPrefix>
    <ng-container *ngTemplateOutlet="prefix"></ng-container>
  </div>
  <input
    matInput
    autoFocus
    autoSize
    [value]="value || null"
    [placeholder]="placeholder"
    (blur)="onSubmit(input.value)"
    (keydown.enter)="onSubmit(input.value)"
    (keydown.escape)="onEscape($event)"
    #input="matInput"
  />
</mat-form-field>
