import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Status } from '@shared/models/status.model';

@Component({
  selector: 'zef-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIcon {
  @Input()
  status: Status = Status.Unknown;

  @Input()
  size: 'small' | 'medium' | 'big' = 'small';
}
